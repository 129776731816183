import { canUseDOM } from 'Shared/DOM/WindowHelper';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import GiftCardCodePageModel from 'Models/Pages/GiftCardCodePage/GiftCardCodePageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import GiftCardCode from 'Commerce/Molecules/GiftCardCard/GiftCardCode';

const GiftCardCodePageWrapper = () => {
  if (!canUseDOM()) {
    return <></>;
  }

  return <GiftCardCodePage />;
};

function GiftCardCodePage() {
  const { contentArea, inEditMode, hero } =
    useCurrentPage<GiftCardCodePageModel>();

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer>
        <GiftCardCode />
      </ContentContainer>
      {Array.isArray(contentArea) && contentArea.length > 0 && (
        <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
          <ContentArea childItems={contentArea} />
        </ContentContainer>
      )}
    </>
  );
}

export default GiftCardCodePageWrapper;
