import { styled } from 'Theme/stitches.config';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useState } from 'react';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import Button from 'Shared/Components/Buttons/Button';
import AddGiftCardResponse from 'Models/GiftCard/AddGiftCardResponse.interface';
import GiftCardModel from 'Models/GiftCard/GiftCardModel.interface';

import { FetchCartAndNotifyAll } from 'Commerce/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

const ADD_URL = API_ROOT_PATH + '/GiftCard/add';

type GiftCardCodeProps = {
  view?: 'checkout' | 'page';
};

function GiftCardCode({ view = 'page' }: GiftCardCodeProps) {
  const { languageRoute } = useAppSettingsData();

  const [isSuccessful, setIsSuccessful] = useState(true);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [giftCard, setGiftCard] = useState<GiftCardModel>();

  const isMobile = useMedia(mediaQueryTypes.bpMax400);

  const {
    errorLabels: { errorFetchingData },
    giftCardLabels: {
      appliedGiftCardInfo,
      enterGiftCardCode,
      giftCardCode,
      giftCardFormatInfo,
      giftCardFormHeading,
      use,
      giftCardValue,
      giftCardValueAfterUse,
      codeInputPlaceholder,
    },
  } = useTranslationData();

  ///TODO: Add validation for gift card code
  const GiftCardConfig = z.object({
    code: z
      .string()
      .min(10, { message: enterGiftCardCode })
      .regex(/^[A-Za-z0-9]*$/, {
        message: giftCardFormatInfo,
      }),
  });

  interface GiftCardConfigType extends z.infer<typeof GiftCardConfig> {}

  const { formState, getValues, register, handleSubmit, setValue, reset } =
    useForm<GiftCardConfigType>({
      resolver: zodResolver(GiftCardConfig),
      mode: 'onSubmit',
      defaultValues: {
        code: '',
      },
    });

  const showGiftCardInfo = isSuccessful && message && !isLoading;

  const submit = async () => {
    setIsLoading(true);

    const payload = getValues();
    const formData = new FormData();
    formData.append('code', payload.code);

    try {
      const res = await fetch(ADD_URL, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        const { isSuccessful, message, giftCard } =
          (await res.json()) as AddGiftCardResponse;
        await FetchCartAndNotifyAll(languageRoute);
        setIsSuccessful(isSuccessful);
        setMessage(message);
        setGiftCard(giftCard);
        if (view === 'page') if (isSuccessful) setValue('code', '');
      } else {
        // Handle error
        setMessage(errorFetchingData);
      }
    } catch (error) {
      // Handle error
      setMessage(errorFetchingData);
    }

    setIsLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      reset();
      setIsSuccessful(true);
    } else {
      setValue('code', e.target.value);
    }
  };

  const giftCardInfoLarge = () => {
    if (showGiftCardInfo) {
      return (
        <>
          <GiftCardInfoHeader>{message}</GiftCardInfoHeader>
          <GiftCardInfoText>{appliedGiftCardInfo}</GiftCardInfoText>
        </>
      );
    }
  };

  const giftCardInfoSmall = () => {
    const payload = getValues();
    if (showGiftCardInfo && payload.code.length > 0 && isSuccessful) {
      return (
        <table>
          <tbody>
            <tr>
              <GiftCardAmountLabel scope="row">
                {giftCardValue}:
              </GiftCardAmountLabel>
              <GiftCardAmount>
                {giftCard?.availableAmount.priceAsString}{' '}
                <Currency>{giftCard?.availableAmount.currencySymbol}</Currency>
              </GiftCardAmount>
            </tr>
            <tr>
              <GiftCardAmountLabel>
                {giftCardValueAfterUse}:
              </GiftCardAmountLabel>
              <GiftCardAmount>
                {giftCard?.availableAmountAfterUse.priceAsString}{' '}
                <Currency>
                  {giftCard?.availableAmountAfterUse.currencySymbol}
                </Currency>
              </GiftCardAmount>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const errorMessage = !isSuccessful
    ? message
    : formState?.errors.code?.message;

  return (
    <GiftCardWrapper page={view === 'page'}>
      {view === 'page' && (
        <Heading tag="h2" size="m">
          {giftCardFormHeading}
        </Heading>
      )}
      <GiftCardForm page={view === 'page'}>
        <InputReactForm
          title={giftCardCode}
          placeholder={codeInputPlaceholder}
          error={!isSuccessful || !!formState?.errors.code}
          errorText={errorMessage}
          type="text"
          name="code"
          register={register}
          onChange={handleInputChange}
          required={true}
        />
        <Button<typeof PrimaryButton>
          element={'PrimaryButton'}
          props={{
            text: use,
            color: 'Regular',
            hug: isMobile ? 'width' : 'height',
            disabled: formState.isSubmitting,
          }}
          css={{ flexShrink: 0 }}
          isLoading={isLoading}
          onClick={handleSubmit(submit)}
        />
      </GiftCardForm>

      <GiftCardInfo
        aria-label={giftCardFormHeading}
        aria-live="assertive"
        aria-atomic="true"
        page={view === 'page'}
      >
        {view === 'page' ? giftCardInfoLarge() : giftCardInfoSmall()}
      </GiftCardInfo>
    </GiftCardWrapper>
  );
}

const width = {
  maxWidth: '600px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const GiftCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  ...width,
  mt: 20,
  pb: 0,
  color: '$onSurface',
  variants: {
    page: {
      false: {
        mt: 0,
        mx: 1,
      },
    },
  },
});

const GiftCardForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  maxWidth: '100%',
  width: '100%',
  mt: 3,
  mb: 0,
  gap: '0',
  '& input[type=text]': {
    width: '100%',
    maxWidth: '100%',
  },
  '& span[role=alert]': {
    maxWidth: '100%',
  },

  '@bpMin401': {
    flexDirection: 'row',
    gap: '8px',
    '& button': {
      position: 'relative',
      top: '24px',
    },
  },

  variants: {
    page: {
      true: {
        '& input[type=text]': {
          width: '100%',
          maxWidth: '536px',
        },
        '& span[role=alert]': {
          maxWidth: '100%',
        },
      },
    },
  },
});

const GiftCardInfo = styled('section', {
  mb: 7,
  '& p': {
    mb: 3,
  },
  variants: {
    page: {
      true: {
        mb: 15,
      },
    },
  },
});

const GiftCardInfoHeader = styled('p', {
  fontSize: '$fontSize500',
  lineHeight: '$lh32',
  fontFamily: 'fontSemiBold',
  mt: 6,
  '@bpMax400': {
    fontSize: '$fontSize300',
    lineHeight: '$lh28',
  },
});

const GiftCardInfoText = styled('p', {
  fontSize: '$fontSize200',
  lineHeight: '$lh24',
  '@bpMax400': {
    fontSize: '$fontSize100',
    lineHeight: '$lh24',
  },
});

const GiftCardAmountLabel = styled('th', {
  fontSize: '$fontSize100',
  fontWeight: '$fontWeightSRegular',
  textAlign: 'left',
});

const GiftCardAmount = styled('td', {
  fontSize: '$fontSize200',
  fontFamily: 'fontSemiBold',
});

const Currency = styled('span', {
  fontSize: '$fontSize75',
});

export default GiftCardCode;
