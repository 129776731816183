import Label from 'DesignSystem/Typography/Label/Label';
import React, { useId } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FormInput, InputGroup } from './Input';
import { styled } from '../../../Theme/stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { CSS } from '@stitches/react';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  errorText?: string;
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  max?: string;
  maxLength?: number;
  css?: CSS;
};

const InputReactForm = ({
  title,
  type = 'Text',
  name,
  disabled,
  error = false,
  errorText,
  register,
  placeholder,
  max,
  maxLength,
  required,
  autoComplete,
  onChange,
  onKeyDown,
  css,
}: InputProps) => {
  const inputId = `name_${useId()}`;
  const errorLabelId = `name_error_${useId()}`;

  const {
    validationLabels: { optional },
  } = useTranslationData();

  return (
    <InputGroup css={css}>
      {title && title !== '' && (
        <>
          <Label htmlFor={inputId}>
            {title}
            {!required && <Optional>{'(' + optional + ')'}</Optional>}
          </Label>
        </>
      )}
      <FormInput
        id={inputId}
        {...(name && register(name))}
        max={max}
        maxLength={maxLength}
        type={type}
        error={error}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-invalid={error ? 'true' : undefined}
        aria-describedby={error ? errorLabelId : undefined}
        aria-required={required}
        disabled={disabled}
      />
      {error && errorText && (
        <Label id={errorLabelId} error={error} role="alert">
          {errorText}
        </Label>
      )}
    </InputGroup>
  );
};

export const ErrorLabel = styled('div', {
  position: 'relative',
  maxW: '640px',
  mb: 2,
});

const Optional = styled('span', {
  display: 'inline-block',
  paddingLeft: '5px',
});

export default InputReactForm;
